import React from 'react';
import styled from 'styled-components';

import Wrapper from '../common/Wrapper';
import PreTitle from '../common/PreTitle';

const AboutWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  padding-top: 64px;
  padding-bottom: 48px;

  & > div {
    width: 48%;
  }
  & img {
    margin-top: 84px;
    width: 100%;
  }

  ${props => props.theme.breakpoints.mobileL} {
    flex-direction: column;
    & > div {
      width: 100%;
    }
    & img {
      margin-top: 18px;
      width: 100%;
    }
  }
`;

const About = () => (
  <div style={{ background: '#fafafa' }}>
    <AboutWrapper id='o-firmie'>
      <div>
        <PreTitle>Informacje o działaności</PreTitle>
        <h2>O firmie</h2>
        <p>
          Szkolenie pracowników z zakresu bhp jest obowiązkowe, jednak ich
          organizacja, przygotowanie oraz przeprowadzenie może przysparzać
          trudności i rodzić wiele pytań.
        </p>
        <p>
          W ramach swoich usług zapewniam elastyczne podejście do każdego
          klienta uwzględniając indywidualne potrzeby.
        </p>
        <p>
          Przeprowadzm profesjonalne szkolenia i kursy z zakresu BHP, Ppoż. i
          Pierwszej Pomocy. Szkolenia wstępne i okresowe w dziedzinie
          bezpieczeństwa i higieny pracy, szkolenia dla osób wyznaczonych do
          udzielania Pierwszej Pomocy.
        </p>
      </div>
      <div>
        <img src='/img/about.jpg' alt='about' />
      </div>
    </AboutWrapper>
  </div>
);

export default About;
