import React from 'react';
import styled from 'styled-components';
import {
  MdWork,
  MdWhatshot,
  MdInsertDriveFile,
  MdSchool,
} from 'react-icons/md';
import { FaHammer, FaFileAlt } from 'react-icons/fa';
import Wrapper from '../common/Wrapper';
import PreTitle from '../common/PreTitle';

const ServicesWrapper = styled(Wrapper)`
  padding-top: 64px;
  padding-bottom: 48px;
  & p {
    text-align: center;
  }
`;
const Title = styled.h2`
  text-align: center;
`;

const TileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  width: 28%;
  & h3 {
    text-align: center;
  }
  & svg {
    color: ${props => props.theme.colors.primary};
    font-size: 40pt;
    align-self: center;
  }
  ${props => props.theme.breakpoints.mobileL} {
    width: 100%;
  }
`;

const Services = () => (
  <ServicesWrapper id='uslugi'>
    <PreTitle centered>Oferta usługowa</PreTitle>
    <Title>Usługi</Title>
    <p>
      Firma zajmuje się kompleksowymi usługami w zakresie BHP i P-poż,
      <br /> a w szczególności:
    </p>
    <TileWrapper>
      <Tile>
        <MdWork />
        <h3>Doradztwo i obsługa firm w zakresie BHP</h3>
        <p>
          Szkolenia, przeprowadzenie kontroli warunków pracy, sporządzanie okresowych analiz stanu BHP oraz inne.
        </p>
      </Tile>
      <Tile>
        <MdSchool />
        <h3>Szkolenie BHP i P-poż.</h3>
        <p>
          Pracodawca ma obowiązek zapewnić szkolenia BHP swoim pracownikom,
          pozwala to na uniknięcie przykrych wypadków przy pracy.
        </p>
      </Tile>
      <Tile>
        <MdInsertDriveFile />
        <h3>Opracowywanie instrukcji BHP</h3>
        <p>
        Pomoc w opracowywaniu wewnętrznych zarządzeń, regulaminów i instrukcji ogólnych - dotyczących BHP.
        </p>
      </Tile>
      <Tile>
        <FaHammer />
        <h3>Oceny ryzyka zawodowego</h3>
        <p>
        Sporządanie dokumentacji oceny ryzyka zawodowego dla wszystkich stanowisk pracy w firmie.
        </p>
      </Tile>
      <Tile>
        <MdWhatshot />
        <h3>Instrukcje bezpieczeństwa pożarowego</h3>
        <p>
          Opracowywanie i wdrożenie odpowiedniej instrukcji P-poż. zmniejsza ryzyko pożaru oraz zwiększa bezpieczeństwo ludzi.
        </p>
      </Tile>
      <Tile>
        <FaFileAlt />
        <h3>Sporządzanie dokumentacji powypadkowej</h3>
        <p>
          Utworzenie dokumentacji powypatkowej jest wymagane po każdym incydencie mogącym zakończyć się uszczebkiem na zdrowiu.
        </p>
      </Tile>
    </TileWrapper>
  </ServicesWrapper>
);

export default Services;
