import React from 'react';
import styled from 'styled-components';
import Wrapper from '../common/Wrapper';
import Form from './Form';

const ContactWrapper = styled.div`
  background-image: url(/img/contact.jpg);
  background-position: center;
  background-size: cover;
  color: ${props => props.theme.colors.textSecondary};
  & > div {
    padding-top: 64px;
    padding-bottom: 48px;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
  }
`;

const ContactContentWrapper = styled(Wrapper)`
  display: flex;
  & > div {
    width: 50%;
  }
  ${props => props.theme.breakpoints.mobileL} {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
`;

const Contact = () => (
  <ContactWrapper id='kontakt'>
    <div>
      <ContactContentWrapper>
        <div>
          <h2>Kontakt</h2>
          <p>BIURO DORADZTWA, NADZORU ORAZ USŁUG BHP I PPOŻ.</p>
          <p>mgr inż. Jacek Lewiński</p>
          <p>ul. Młodzieżowa 24, 89-604 Chojnice</p>
          <p>NIP 555-122-40-28</p>
          <p>tel. +48 602 882 678</p>
          <p>e-mail: biuro@bhp-lewinski.com</p>
        </div>
        <Form />
      </ContactContentWrapper>
    </div>
  </ContactWrapper>
);

export default Contact;
