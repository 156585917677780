import styled from 'styled-components';

const PreTitle = styled.p`
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
  font-size: 10pt;
  text-align: ${props => (props.centered ? 'center' : 'left')};
`;

export default PreTitle;
