import React from 'react';
import styled from 'styled-components';
import { MdPhoneAndroid, MdLocationOn } from 'react-icons/md';
import { FaFacebookF } from 'react-icons/fa';
import Wrapper from '../common/Wrapper.jsx';

const ContactWrapper = styled.div`
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.textSecondary};
  font-size: 10pt;
  padding: 8pt 0;
  & a {
    color: ${props => props.theme.colors.textSecondary};
    text-decoration: none;
  }
  & a:first-child {
    display: inline-block;
    margin-right: 20pt;
  }
`;

const ContactBar = () => (
  <ContactWrapper>
    <Wrapper style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <MdLocationOn /> Młodzieżowa 24, 89-600 Chojnice
      </div>
      <div>
        <a href='tel:+48602882678'>
          <MdPhoneAndroid />
          +48 602 882 678
        </a>
        <a
          href='https://www.facebook.com/bhpchojnice/'
          rel="noopener noreferrer"
          target='_blank'
          title='BHP Chojnice Facebook'>
          <FaFacebookF />
        </a>
      </div>
    </Wrapper>
  </ContactWrapper>
);

export default ContactBar;
