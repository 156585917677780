import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Wrapper from './Wrapper.jsx';

const SlideBackground = styled.section`
  background-image: url(${props => props.image});
  background-position: center;
  background-size: cover;
  height: 600px;
  position: relative;
`;

const Opacity = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const HeadText = styled.h2`
  color: ${props => props.theme.colors.textSecondary};
  font-size: 28pt;
  width: 50%;
  ${props => props.theme.breakpoints.mobileL} {
    width: 100%;
  }
`;

const SlideText = styled.p`
  color: ${props => props.theme.colors.textSecondary};
  width: 50%;
  ${props => props.theme.breakpoints.mobileL} {
    width: 100%;
  }
`;

const SlideContent = styled(Wrapper)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const Slide = ({ image, header, content, buttons }) => {
  return (
    <SlideBackground image={image}>
      <Opacity>
        <SlideContent>
          <HeadText>{header}</HeadText>
          <SlideText>{content}</SlideText>
          {buttons ? <div>{buttons} </div> : ''}
        </SlideContent>
      </Opacity>
    </SlideBackground>
  );
};

Slide.propTypes = {
  image: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  content: PropTypes.string,
  buttons: PropTypes.any,
};

export default Slide;
