import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

const ButtonPropTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'white']),
};
const ButtonDefaultProps = {
  color: 'primary',
};

const Button = styled.button`
  background: ${({ color, theme }) => {
    switch (color) {
      case 'white':
        return theme.colors.textSecondary;
      case 'secondary':
        return theme.colors.text;
      default:
        return theme.colors.primary;
    }
  }};
  border-radius: 32px;
  color: ${({ color, theme }) =>
    color === 'white' ? theme.colors.primary : theme.colors.textSecondary};
  display: inline-block;
  font-size: 12pt;
  font-weight: bold;
  padding: 18px 24px;
  text-decoration: none;
`;
Button.propTypes = ButtonPropTypes;
Button.defaultProps = ButtonDefaultProps;
export const LinkButton = styled(Link)`
  background: ${({ color, theme }) => {
    switch (color) {
      case 'white':
        return theme.colors.textSecondary;
      case 'secondary':
        return theme.colors.text;
      default:
        return theme.colors.primary;
    }
  }};
  border-radius: 32px;
  color: ${({ color, theme }) =>
    color === 'white' ? theme.colors.primary : theme.colors.textSecondary};
  display: inline-block;
  font-size: 12pt;
  font-weight: bold;
  padding: 18px 24px;
  text-decoration: none;
`;
LinkButton.propTypes = ButtonPropTypes;
LinkButton.defaultProps = ButtonDefaultProps;

export default Button;
