import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import 'normalize.css/normalize.css';
import theme from '../theme.json';
const Container = styled.div`
  color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.font.family};
  font-size: ${props => props.theme.font.size.text};
  line-height: 1.2;
`;
export default props => (
  <>
    <Helmet>
      <link
        href='https://fonts.googleapis.com/css?family=Open+Sans:400,700&amp;subset=latin-ext'
        rel='stylesheet'
      />
    </Helmet>
    <ThemeProvider theme={theme}>
      <Container {...props} />
    </ThemeProvider>
  </>
);
