import React from 'react';
import styled from 'styled-components';
import Wrapper from '../common/Wrapper';

const FooterWrapper = styled.footer`
  background: ${({ theme }) => theme.colors.accent};
  color: ${props => props.theme.colors.accentSecondary};
  font-size: 11pt;
`;

const Description = styled.div`
  border-top: 1px solid #333333;
  text-align: center;
  padding: 30px 0;
`;

const Footer = props => (
  <FooterWrapper>
    <Wrapper {...props} />
    <Description>bhp-lewinski.com</Description>
  </FooterWrapper>
);

export default Footer;
