import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledLink = styled(Link)`
  color: ${props =>
    props.reverse ? props.theme.colors.textSecondary : props.theme.colors.text};
    text-decoration: none;
  &:hover {
    color: ${props =>
      props.reverse ? props.theme.colors.secondary : props.theme.colors.primary};
  }
`;
export default StyledLink;
