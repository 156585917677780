import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Services from '../components/Services/Services';
import About from '../components/About/About';
import Slide from '../components/common/Slide';
import Contact from '../components/Contact/Contact';
import { LinkButton } from '../components/common/Button';
import schema from '../schema.json';

export default () => (
  <Layout>
    <Helmet>
      <html lang='pl' />
      <title>
        Szkolenia BHP Chojnice | Kursy, doradztwo i obsługa BHP i PPOŻ
      </title>
      <meta
        name='description'
        content='Kursy i szkolenia BHP (bezpieczeństwa i higieny pracy), ppoż oraz obsługa firm bhp w miastach Chojnice, Człuchów i Tuchola'
      />
      <meta
        name='keywords'
        content='chojnice bhp, tuchola bhp, brusy bhp, obsługa bhp firm, szkolenia bhp, szkolenia okresowe, prawo pracy, ppoż'
      />
      <meta name='theme-color' content='#e78200' />
      <script
        type='application/ld+json'
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </Helmet>
    <Header />
    <Slide
      buttons={
        <>
          <LinkButton to='/#o-firmie'>Więcej o firmie</LinkButton>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <LinkButton color='white' to='/#kontakt'>
            Kontakt
          </LinkButton>
        </>
      }
      content='Firma zajmuje się profesjonalnymi usługami w zakresie bezpieczeństwa i higieny pracy oraz obsługą firm wraz ze szkoleniami.
Dzięki doświadczeniu zdobytemu na przestrzeni kilkunastu lat pracy dokładam wszelkich starań, aby sprostać oczekiwaniom każdego klienta.'
      header='Szkolenia BHP oraz ppoż'
      image='/img/slide.jpg'
    />
    <About />
    <Services />
    <Contact />
    <Footer />
  </Layout>
);
