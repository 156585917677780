import React from 'react';
import styled from 'styled-components';
import Wrapper from '../common/Wrapper';
import Link from '../common/Link';
import ContactBar from '../ContactBar/ContactBar';

const MenuWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`;
const Logo = styled.img`
  max-width: 80px;
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Menu = styled.nav`
  & ul {
    display: flex;
  }
  ${props => props.theme.breakpoints.mobileL} {
    display: none;
  }
`;
const MenuItem = styled.li`
  list-style: none;
  margin-right: 18pt;
  &:last-child {
    margin-right: 0;
  }
`;

const MenuLink = styled(Link)`
  position: relative;
  font-weight: bold;
  font-size: 12pt;
  transition: color 0.2s ease;
  &:after {
    background: none;
    bottom: -2px;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transition: background 0.2s ease, width 0.2s ease;
    width: 0px;
  }
  &:hover:after {
    background: ${props => props.theme.colors.primary};
    width: 30px;
  }
`;

const Header = () => (
  <header id='glowna'>
    <ContactBar />
    <MenuWrapper>
      <LogoWrapper>
        <Logo src='/logo.png' alt='logo' />
        &nbsp;Biuro doradztwa, nadzoru BHP oraz usług ppoż
      </LogoWrapper>
      <Menu>
        <ul>
          <MenuItem>
            <MenuLink to='/#glowna'>Strona główna</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to='/#o-firmie'>O firmie</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to='/#uslugi'>Usługi</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to='/#kontakt'>Kontakt</MenuLink>
          </MenuItem>
        </ul>
      </Menu>
    </MenuWrapper>
  </header>
);

export default Header;
