import React, { useRef } from 'react';
import styled from 'styled-components';

const StyledForm = styled.form`
  background: ${props => props.theme.colors.primary};
  padding: 24px;
`;

const StyledInput = styled.input`
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 18px;
`;

const StyledTextArea = styled.textarea`
  padding: 8px;
  height: 120px;
  box-sizing: border-box;
  margin-bottom: 18px;
  width: 100%;
`;

const StyledSubmit = styled.button`
  background: ${props => props.theme.colors.accent};
  border: none;
  box-sizing: border-box;
  color: ${props => props.theme.colors.textSecondary};
  cursor: pointer;
  font-size: 12pt;
  font-weight: bold;
  padding: 8px;
  width: 100%;
`;

const Form = () => {
  const titleRef = useRef(null);
  const messageRef = useRef(null);

  return (
    <StyledForm
      onSubmit={e => {
        e.preventDefault();
        const url = `subject=${titleRef.current.value}&body=${
          messageRef.current.value
        }`;
        window.location.href = `mailto:biuro@bhp-lewinski.com?${url}`;
      }}>
      <p>Formularz kontaktowy</p>
      <StyledInput ref={titleRef} placeholder='Tytuł' type='text' />
      <StyledTextArea ref={messageRef} placeholder='Treść wiadomości' />
      <StyledSubmit type='submit'>Wyślij</StyledSubmit>
    </StyledForm>
  );
};

export default Form;
